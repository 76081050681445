var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "600px",
        "content-class": "staked-create-pw-dialog",
      },
      model: {
        value: _vm.onCreatePassword,
        callback: function ($$v) {
          _vm.onCreatePassword = $$v
        },
        expression: "onCreatePassword",
      },
    },
    [
      _c(
        "div",
        { staticClass: "white pa-3 pa-sm-8 position--relative" },
        [
          _c(
            "v-btn",
            {
              staticClass: "close-button",
              attrs: { icon: "" },
              on: {
                click: function ($event) {
                  _vm.onCreatePassword = false
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          ),
          _c("div", { staticClass: "mew-heading-2 text-center" }, [
            _vm._v("Choose password"),
          ]),
          _c(
            "div",
            { staticClass: "mt-8" },
            [
              _c("div", [_vm._v("Protect your keystore file with password")]),
              _c("mew-input", {
                staticClass: "mt-8",
                attrs: {
                  type: "password",
                  hint: "Password must be 8 or more characters",
                  "has-clear-btn": true,
                  "error-messages": _vm.passwordErrMessages,
                  rules: [
                    function (value) {
                      return !!value || "Required"
                    },
                  ],
                  label: "New password",
                },
                model: {
                  value: _vm.password,
                  callback: function ($$v) {
                    _vm.password = $$v
                  },
                  expression: "password",
                },
              }),
              _c("mew-input", {
                staticClass: "mt-2",
                attrs: {
                  "error-messages": _vm.confirmPasswordErrMessages,
                  type: "password",
                  label: "Confirm password",
                },
                model: {
                  value: _vm.confirmPassword,
                  callback: function ($$v) {
                    _vm.confirmPassword = $$v
                  },
                  expression: "confirmPassword",
                },
              }),
              _c(
                "div",
                { staticClass: "mt-1 greyLight terms-container pa-5" },
                [
                  _c("mew-checkbox", {
                    attrs: {
                      "class-name": "textMedium--text",
                      label: _vm.userTermsLabel,
                    },
                    model: {
                      value: _vm.userTermsAgreed,
                      callback: function ($$v) {
                        _vm.userTermsAgreed = $$v
                      },
                      expression: "userTermsAgreed",
                    },
                  }),
                ],
                1
              ),
              _c("mew-button", {
                staticClass: "d-block mx-auto mt-8",
                attrs: {
                  "icon-type": "mdi",
                  "icon-align": "left",
                  icon: "mdi-download",
                  "has-full-width": _vm.$vuetify.breakpoint.smAndDown,
                  "btn-size": "xlarge",
                  disabled:
                    !_vm.password ||
                    _vm.confirmPassword === "" ||
                    _vm.confirmPasswordErrMessages !== "" ||
                    _vm.passwordErrMessages !== "" ||
                    !_vm.userTermsAgreed,
                  title: "Download keystore file",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.generateKeystore.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }